<template>
    <section v-scrollanimation class="research-page">

        <div class="h-50px"></div>

        <h2 class="heading">Publications</h2>
        <p class="md:text-size-24px text-size-20px md:px-10px w-[100%] max-w-[770px] md:leading-relaxed leading-normal">
            {{ Publications.des }}
        </p>
        <div class="code-grid">
            <template v-for="(code, index) in Publications.publication" :key="index">
                <div v-scrollanimation :style="'transition-delay:' + index * 100 + 'ms'">
                    <div class="code-item">
                        <div class="code-item-inner">
                            <header>
                                <div class="item-top">
                                    <div class="text-[var(--primary)] text-size-32px">
                                      <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 110.46 122.88"
                                            width="30" class="fill-[var(--primary)]">
                                            <path
                                                d="M78.06,80.32a2.94,2.94,0,0,1,1.8.56c.7.45,1.49,1.33,2.47,1.88,1.38.79,3.93-.29,5.23,1.65a5.11,5.11,0,0,1,.85,2.89,5,5,0,0,0,1.2,3.08c1.6,2.12,1.93,3.53,1.11,5-.57,1-1.75,1.56-2,2.19-.58,1.35.06,2.36-.73,3.94a4.19,4.19,0,0,1-2.55,2.17c-1,.31-1.93-.14-2.7.19-1.35.57-2.34,1.89-3.42,2.23a4.06,4.06,0,0,1-1.24.19,4.11,4.11,0,0,1-1.24-.19c-1.08-.33-2.07-1.66-3.42-2.23-.77-.32-1.73.12-2.7-.19a4.11,4.11,0,0,1-2.54-2.17c-.8-1.58-.16-2.59-.74-3.94-.27-.64-1.45-1.2-2-2.19-.83-1.47-.5-2.88,1.11-5a5.06,5.06,0,0,0,1.2-3.08,5.07,5.07,0,0,1,.85-2.89c1.3-1.94,3.86-.85,5.23-1.65,1-.55,1.77-1.44,2.47-1.88a3,3,0,0,1,1.8-.56ZM15.63,122.87c-3.94.06-7.4-.11-10.13-1.39C2.05,119.86,0,116.8,0,111v-5.29H15.1V13.39c-.22-5.31,1.07-8.61,3.44-10.67S24.11.08,27.89,0c.18-.09,74.82,0,82.57,0V110.15c0,4.46-1.1,7.62-3.36,9.73s-5.46,3-9.78,3ZM34.05,23.51a2.13,2.13,0,1,1,0-4.26H72.9a2.13,2.13,0,0,1,0,4.26Zm0,14.51a2.13,2.13,0,0,1,0-4.26h57.1a2.13,2.13,0,0,1,0,4.26Zm0,43.5a2.12,2.12,0,0,1,0-4.24H53.76a2.12,2.12,0,0,1,0,4.24Zm0-14.49a2.13,2.13,0,0,1,0-4.26h57.1a2.13,2.13,0,0,1,0,4.26Zm0-14.54a2.1,2.1,0,1,1,0-4.2H91.48a2.1,2.1,0,1,1,0,4.2ZM33,96.81c4.94-6.49,9.15-3.55,15,.55l.87.61c1.61,1.12,3.48-.53,5.25-2.1a22,22,0,0,1,2.62-2.09l1.82,2.87a18.92,18.92,0,0,0-2.18,1.76c-2.76,2.43-5.65,5-9.44,2.35l-.89-.62c-4.31-3-7.4-5.17-10.37-1.27L33,96.81ZM15.1,110H4.26v1c0,3.83,1.14,5.74,3,6.63s4.61,1,7.81,1V110Zm91.11.18V4.3H27.91c-2.83,0-5.15.39-6.59,1.63s-2.14,3.47-2,7.36V118.61h78c3.22,0,5.51-.56,6.88-1.85s2-3.4,2-6.61ZM78.06,85.52a7.78,7.78,0,1,1-7.77,7.78,7.78,7.78,0,0,1,7.77-7.78Z" />
                                        </svg>
                                    </div>
                                    <div class="item-links">
                                        <div class="item-links-toggle customTooltip" :gloss="link.tooltip"
                                            v-for="link in code.links" :key="link.link" @click="openSite(link.link)">
                                            <Icon class="icon" :name="link.icon" :size="16" style="margin-top: 5px" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 class="md:text-size-17px text-size-18 font-650 mt-20px text-[var(--primary)]">{{
                                        code.title }}</h3>
                                    <div class="md:text-size-15px text-size-18 md:leading-snug leading-tight mt-10px">
                                        {{ code.authors }}</div>
                                    <div class="h-10px"></div>
                                    <div class="card-footer">
                                        <a :href="code.venue_link" target="_blank"
                                            class="md:text-size-15px text-size-22 font-700 mt-20px text-[var(--primary)]">{{
                                                code.venue
                                            }}</a>
                                    </div>
                                </div>
                            </header>

                            <footer class="mt-20px">
                                <ul class="flex gap-10px flex-wrap">
                                    <li class="flex items-center gap-5px" v-for="tech in code.techs" :key="tech.name">
                                        <p class="font-weight-medium"> {{ tech.name }}</p>
                                    </li>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>


    <section v-scrollanimation class="service-page">
    <h2 class="heading">Service & Affiliations</h2>
    <div class="service-grid">
        <template v-for="(service, index) in Services" :key="index">
            <div class="service-item">
                <h3 class="service-title">{{ service.title }}</h3>
                <p class="service-description">{{ service.description }}</p>
            </div>
        </template>
    </div>
</section>




</template>

<script>
import Icon from './../../components/Icons';
export default {
    components: { Icon },

    data() {
        return {

              Services: [
            {
    title: 'IEEE Access',
    description: 'Reviewer'
},
{
    title: 'Institute of Electrical and Electronics Engineers (IEEE)',
    description: 'Member'
},
{
    title: 'ACL Special Interest Group on Building Educational Applications. (ACL-SIGEDU)',
    description: 'Member'
},
{
    title: 'IEEE Communications Society (IEEE-ComSoc)',
    description: 'Member'
},
{
    title: 'International Society of the Learning Sciences (ISLS)',
    description: 'Member'
},
{
    title: 'ACL Special Interest Group on Arabic Natural Language Processing (ACL-SIGARAB)',
    description: 'Member'
}

        ],
                  Publications: { 
                    
                    publication: [


                         {
                            title: 'Building Intelligent Chatbots: Tools, Technologies, and Approaches',
                            authors: 'Hamza El Alaoui, Zakaria El Aouene, and Violetta Cavalli-Sforza',
                            type: 'Conference Article',
                        venue: 'IEEE/International Conference on Innovative Research in Applied Science, Engineering and Technology \'23',
                        venue_link: 'http://www.iraset.org/2023/',
                        links: [
                            {
                                link: 'https://ieeexplore.ieee.org/document/10153005',
                                icon: 'link',
                                tooltip: 'View'
                            }
                        ]
                        },
                        
                        {
                            title: 'Impact of Hot Arid Climate on Optimal Placement of EV Charging Stations',
                            authors: 'Hamza El Hafdaoui, Hamza El Alaoui, Salma Mahidat, Zakaria El Harmouzi, and Ahmed Khallaayoun',
                            type: 'Journal Article',
                            venue: 'MDPI/Energies',
                            venue_link: 'https://www.mdpi.com/journal/energies',
                            links: [
                                {
                                    link: 'https://www.mdpi.com/1996-1073/16/2/753',
                                    icon: 'link',
                                    tooltip: 'View'
                                }
                            ]
                        },
                       
                        {
                            title: 'Sustainable railways for Morocco: a comprehensive energy and environmental assessment',
                            authors: 'Hamza El Alaoui, Ahmed Bazzi, Hamza El Hafdaoui, Ahmed Khallaayoun and Rachid Lghoul',
                            type: 'Journal Article',
                        venue: 'Springer-Nature/Journal of Umm Al-Qura Uni. for Engineering',
                        venue_link: 'https://www.springer.com/journal/43995',
                        links: [
                            {
                                link: 'https://link.springer.com/article/10.1007/s43995-023-00034-0',
                                icon: 'link',
                                tooltip: 'View'
                            }
                        ]
                        },
                        {
                            title: 'Deep Reinforcement Learning for Autonomous Vehicle Intersection Navigation',
                            authors: 'Badr Ben Elallid, Hamza El Alaoui, Nabil Benamar',
                            type: 'Preprint',
                        venue: "IEEE/International Conference on Innovation and Intelligence for Informatics, Computing, and Technologies '23'",
                        venue_link: 'https://iiict.uob.edu.bh/3ict23/',
                        links: [
                            {
                                link: 'https://ieeexplore.ieee.org/abstract/document/10391453/',
                                icon: 'link',
                                tooltip: 'View'
                            }
                        ]
                        },
                    ]
            },
            
        }
},
methods: {
    openSite(link) {
        window.open(link, '_blank')
    }
}
}
</script>

<style lang="postcss">

 
.service-page {
    @apply max-w-[1000px] mx-auto my-50px;
}

.heading {
    @apply lg:text-size-30px md:text-size-40px text-size-36px font 400 text-[var(--primary)] tracking-tight mb-15px md:px-10px;
 
}
 
.service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 12px;
}

.service-item {
    padding: 8px;
    border: 1px solid var(--lightSlate);
    border-radius: var(--border-radius);
}

.service-title {
    @apply text-size-17px font-600 text-[var(--primary)];
}

.service-description {
    @apply text-size-18px mt-10px;
}



.research-page {
    @apply max-w-[1000px] mx-auto my-100px;

    &.a-before-enter {
        opacity: 0;
        transform: translateY(20px);
        transition: 0.3s ease-in;
    }

    &.a-enter {
        opacity: 1;
        transform: translateY(0);
    }

    .card-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;

    }

    .code-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        position: relative; 
        gap: 30px;

        .a-before-enter {
            opacity: 0;
            transform: translateY(20px);
            transition: 0.2s ease-in-out;
        }

        .a-enter {
            opacity: 1;
            transform: translateY(0);
        }

        .code-item {
            visibility: visible;
            box-shadow: 0 10px 30px -15px var(--navy-shadow);
            border-radius: var(--border-radius);
            transition: 0.2s;
            height: 100%;
            z-index: 1;
            background-color: var(--lightBackground);

            .code-item-inner {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 2rem 1.75rem;
                height: 100%;

                header {
                    .item-top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .item-links {
                            display: flex;
                            margin-right: -15px;
                            color: var(--lightSlate) !important;

                            .item-links-toggle {
                                padding: 0px 5px;
                                cursor: pointer;

                                .icon {
                                    color: var(--lightSlate) !important;
                                }

                                &:hover {
                                    .icon {
                                        color: var(--primary) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                transform: translate(0px, -5px);
                box-shadow: var(--black-shadow);
                background-color: var(--lightBackground);
            }
        }
    }
}
</style>